import React from 'react';

const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20" fill="currentColor">
    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
  </svg>
);

const MediumIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="20" height="20" fill="currentColor">
    <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z"/>
  </svg>
);

const FileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="20" height="20" fill="currentColor">
    <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
  </svg>
);

const ProfileSection = ({ title, children }) => (
  <section className="mb-8">
    <h2 className="text-lg font-semibold mb-3 text-white">{title}</h2>
    {children}
  </section>
);

export default function App() {
  return (
    <div className="bg-[#0a0a0a] text-gray-300 min-h-screen flex items-center justify-center p-4 font-sans antialiased">
      <div className="w-full max-w-4xl bg-[#111] shadow-xl rounded-lg overflow-hidden border border-gray-800">
        <div className="md:flex">
          <div className="md:w-1/3 p-8">
            <header className="text-center md:text-left">
              <img
                src={require('./images/alpha.jpeg')}
                alt="Alpha Tshibangu"
                className="w-32 h-32 rounded-full mx-auto md:mx-0 mb-4 object-cover border-2 border-gray-700"
              />
              <h1 className="text-2xl font-bold text-white">Alpha Tshibangu</h1>
              <p className="text-gray-400">Software Engineer</p>
            </header>
            <footer className="mt-6 text-center md:text-left">
              <div className="flex flex-col space-y-2">
                <a
                  href="https://www.linkedin.com/in/alpha-tshibangu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white flex items-center"
                >
                  <LinkedInIcon />
                  <span className="ml-2 text-sm">LinkedIn</span>
                </a>
                <a
                  href="https://medium.com/@alphatshibangu/what-i-learnt-from-a-conversation-with-one-of-singapores-most-prominent-entrepreneurs-b81a0b1107d8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white flex items-center"
                >
                  <MediumIcon />
                  <span className="ml-2 text-sm">Medium</span>
                </a>
                <a
                  href="https://docs.google.com/document/d/1kM8E8UjfSA1c9_W0BYgnDTrLjSp45sR4/edit?usp=sharing&ouid=111384638528796485945&rtpof=true&sd=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white flex items-center"
                >
                  <FileIcon />
                  <span className="ml-2 text-sm">Resume</span>
                </a>
              </div>
            </footer>
          </div>
          <div className="md:w-2/3 p-8 md:border-l border-gray-800">
            <main className="space-y-6">
              <ProfileSection title="About Me">
                <p className="text-sm text-gray-400">
                  I'm a software engineer with a passion for financial technology, AI, and investment. 
                  Currently working at Macquarie Group, developing internal trade and risk-management systems.
                </p>
              </ProfileSection>
              <ProfileSection title="Experience">
                <ul className="list-disc pl-5 text-sm text-gray-400">
                  <li>Software Engineer at Macquarie Group</li>
                  <li>Software Engineer at Chocolate Finance</li>
                  <li>Business Analyst at Chartertech</li>
                </ul>
              </ProfileSection>
              <ProfileSection title="Education">
                <ul className="list-disc pl-5 text-sm text-gray-400">
                  <li>Master of Commerce, Finance - University of New South Wales</li>
                  <li>Bachelor of Business Informatics - University of Canberra</li>
                </ul>
              </ProfileSection>
              <ProfileSection title="Skills">
                <p className="text-sm text-gray-400">
                  Financial modelling, data analysis, machine learning, software development (Python, C++)
                </p>
              </ProfileSection>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}